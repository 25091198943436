
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

@Component({})
export default class BooleanSelect extends Vue {
  @Prop({ required: false, default: false })
  protected isExpanded!: boolean;

  @Prop({ required: false, default: "" })
  protected placeholder!: string;

  @Prop({ required: false, default: "general.yes" })
  protected positiveI18nKey!: string;

  @Prop({ required: false, default: "general.no" })
  protected negativeI18nKey!: string;

  protected get selectData(): Array<{ value: number; i18n: string }> {
    return [
      { value: 1, i18n: this.positiveI18nKey },
      { value: 0, i18n: this.negativeI18nKey },
    ];
  }

  @VModel()
  protected selected!: boolean;
}
