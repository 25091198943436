
import { Component, Prop, VModel, Watch } from "vue-property-decorator";
import IMachine from "@/interfaces/IMachine";
import { mixins } from "vue-class-component";
import { SelectsStoreMixin } from "@/mixins/Stores/SelectsStoreMixin";

@Component({
  components: {},
})
export default class MachineSelect extends mixins(SelectsStoreMixin) {
  @Prop({ required: false, default: "" })
  protected size!: string;

  @Prop({ required: false, default: false })
  protected isExpanded!: boolean;

  protected placeholder = "mplaceholder";

  protected name!: IMachine;

  protected selectedMachineName: string | null = null;

  @VModel()
  protected selectedMachine!: IMachine | null;

  @Watch("selectedMachine", { immediate: true, deep: true })
  protected onSelectedMachineChanged(machine: IMachine | null): void {
    this.selectedMachineName = machine?.machine ?? "";
  }

  protected loadMachines(): void {
    this.loadSelectMachinesAction();
  }

  protected onSelect(machine: IMachine): void {
    console.debug("on select", machine);
    this.selectedMachine = machine;
    this.$emit("select machine", machine);
  }

  protected created(): void {
    this.loadMachines();
  }
}
