var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-three-fifths"},[_c('DefaultForm',{attrs:{"is-saving":_vm.getIpAddressIsSaving,"mode":_vm.mode},on:{"submit":_vm.onSubmit,"abort":_vm.onAbort},scopedSlots:_vm._u([{key:"default",fn:function(formSettings){return [_c('div',[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":_vm.Str.capitalize(
                  String(_vm.$tc('modules.base_data.ip_address.ip_address', 1))
                ),"type":_vm.getIpAddressValidationErrors &&
                _vm.getIpAddressValidationErrors.ip_address
                  ? 'is-danger'
                  : '',"message":_vm.getIpAddressValidationErrors &&
                _vm.getIpAddressValidationErrors.ip_address
                  ? _vm.getIpAddressValidationErrors.ip_address
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(
                    _vm.$t('modules.base_data.ip_address.placeholder.ip_address')
                  ),"required":""},model:{value:(_vm.ipAddress),callback:function ($$v) {_vm.ipAddress=$$v},expression:"ipAddress"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":_vm.Str.capitalize(
                  String(_vm.$t('modules.base_data.ip_address.data_center'))
                ),"type":_vm.getIpAddressValidationErrors &&
                _vm.getIpAddressValidationErrors.data_center
                  ? 'is-danger'
                  : '',"message":_vm.getIpAddressValidationErrors &&
                _vm.getIpAddressValidationErrors.data_center
                  ? _vm.getIpAddressValidationErrors.data_center
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(
                    _vm.$t('modules.base_data.ip_address.placeholder.data_center')
                  ),"required":""},model:{value:(_vm.dataCenter),callback:function ($$v) {_vm.dataCenter=$$v},expression:"dataCenter"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":_vm.Str.capitalize(
                  String(_vm.$t('modules.base_data.ip_address.dhcp'))
                ),"type":_vm.getIpAddressValidationErrors &&
                _vm.getIpAddressValidationErrors.dhcp
                  ? 'is-danger'
                  : '',"message":_vm.getIpAddressValidationErrors &&
                _vm.getIpAddressValidationErrors.dhcp
                  ? _vm.getIpAddressValidationErrors.dhcp
                  : ''}},[_c('BooleanSelect',{attrs:{"is-expanded":true},model:{value:(_vm.dhcp),callback:function ($$v) {_vm.dhcp=$$v},expression:"dhcp"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":_vm.Str.capitalize(
                  String(_vm.$tc('modules.base_data.ip_address.network', 1))
                ),"type":_vm.getIpAddressValidationErrors &&
                _vm.getIpAddressValidationErrors.network
                  ? 'is-danger'
                  : '',"message":_vm.getIpAddressValidationErrors &&
                _vm.getIpAddressValidationErrors.network
                  ? _vm.getIpAddressValidationErrors.network
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fa-solid fa-network-wired","placeholder":String(
                    _vm.$t('modules.base_data.ip_address.placeholder.network')
                  ),"required":""},model:{value:(_vm.network),callback:function ($$v) {_vm.network=$$v},expression:"network"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":_vm.Str.capitalize(
                  String(_vm.$tc('modules.base_data.ip_address.gateway', 1))
                ),"type":_vm.getIpAddressValidationErrors &&
                _vm.getIpAddressValidationErrors.gateway
                  ? 'is-danger'
                  : '',"message":_vm.getIpAddressValidationErrors &&
                _vm.getIpAddressValidationErrors.gateway
                  ? _vm.getIpAddressValidationErrors.gateway
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fa-solid fa-dungeon","placeholder":String(
                    _vm.$t('modules.base_data.ip_address.placeholder.gateway')
                  ),"required":""},model:{value:(_vm.gateway),callback:function ($$v) {_vm.gateway=$$v},expression:"gateway"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":_vm.Str.capitalize(
                  String(_vm.$tc('modules.base_data.ip_address.netmask', 1))
                ),"type":_vm.getIpAddressValidationErrors &&
                _vm.getIpAddressValidationErrors.netmask
                  ? 'is-danger'
                  : '',"message":_vm.getIpAddressValidationErrors &&
                _vm.getIpAddressValidationErrors.netmask
                  ? _vm.getIpAddressValidationErrors.netmask
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fa-solid fa-dungeon","placeholder":String(
                    _vm.$t('modules.base_data.ip_address.placeholder.netmask')
                  ),"required":""},model:{value:(_vm.netmask),callback:function ($$v) {_vm.netmask=$$v},expression:"netmask"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":_vm.Str.capitalize(
                  String(_vm.$t('modules.base_data.ip_address.machine'))
                ),"type":_vm.getIpAddressValidationErrors &&
                _vm.getIpAddressValidationErrors.machine_id
                  ? 'is-danger'
                  : '',"message":_vm.getIpAddressValidationErrors &&
                _vm.getIpAddressValidationErrors.machine_id
                  ? _vm.getIpAddressValidationErrors.machine_id
                  : ''}},[_c('MachineSelect',{model:{value:(_vm.machine),callback:function ($$v) {_vm.machine=$$v},expression:"machine"}})],1)],1)]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }