
import { Component, Prop, Watch } from "vue-property-decorator";
import FormMixin from "@/mixins/Crud/FormMixin";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import SaveButton from "@/components/buttons/table_actions/SaveButton.vue";
import IIpAddress from "@/interfaces/IIpAddress";
import AddressesForm from "@/components/forms/common/AddressesForm.vue";
import Json from "../../../helper/Json";
import { mixins } from "vue-class-component";
import { IpAddressStoreMixin } from "@/mixins/Stores/IpAddressStoreMixin";
import Str from "@/helper/Str";
import CustomerSelect from "@/components/form_inputs/CustomerSelect.vue";
import ResponsibleEmployeeSelect from "@/components/form_inputs/ResponsibleEmployeeSelect.vue";
import IMachine from "@/interfaces/IMachine";
import MachineSelect from "@/components/form_inputs/MachineSelect.vue";
import IP from "@/helper/IP";
import BooleanSelect from "@/components/form_inputs/BooleanSelect.vue";

@Component({
  computed: {
    Str() {
      return Str;
    },
    Json() {
      return Json;
    },
  },
  components: {
    BooleanSelect,
    MachineSelect,
    ResponsibleEmployeeSelect,
    CustomerSelect,
    AddressesForm,
    SaveButton,
    DefaultForm,
  },
  mixins: [IpAddressStoreMixin, FormMixin],
})
export default class IpAddressForm extends mixins(
  FormMixin,
  IpAddressStoreMixin
) {
  protected name = "IpAddressForm.vue";

  @Prop({ required: false, default: "create" })
  public mode!: string;

  @Prop({ required: false, default: null })
  public data!: IIpAddress;

  @Watch("data", { immediate: true, deep: true })
  protected onDataChanged(data: IIpAddress | null): void {
    if (data) {
      this.setFormValues(data);
    }
  }

  protected id?: number;

  protected ipAddress = "";
  protected netmask = "";
  protected gateway = "";
  protected network = "";
  protected dhcp = false;
  protected dataCenter = "";

  protected machine: IMachine | null = null;

  protected get machineId(): number | null {
    return this.machine?.id ?? null;
  }

  protected createdAt?: Date | string;
  protected updatedAt?: Date | string;

  public getFormValues(): IIpAddress {
    let ipAddress: IIpAddress = {
      ip_address: this.ipAddress,
      netmask: this.netmask,
      gateway: this.gateway,
      network: this.network,
      dhcp: this.dhcp,
      data_center: this.dataCenter,
      machine_id: this.machineId,
    };
    if (this.data && this.data.id) {
      ipAddress.id = this.id;
    }
    return ipAddress;
  }

  protected setFormValues(data: IIpAddress): void {
    if (data) {
      this.id = data.id;
      this.ipAddress = data.ip_address;
      this.netmask = data.netmask;
      this.gateway = data.gateway;
      this.network = data.network;
      this.dhcp = data.dhcp;
      this.dataCenter = data.data_center;
      this.machine = data.machine ?? null;
      this.createdAt = data.created_at;
      this.updatedAt = data.updated_at;
    }
  }

  public init() {
    IP.netInfos("192.168.252.5");
  }
}
