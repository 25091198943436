import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { IpAddress } from "@/config/Modules";

const IpAddressModule = namespace(IpAddress.store);

@Component({})
export class IpAddressStoreMixin extends Vue {
  /**
   * Actions
   */
  @IpAddressModule.Action("create")
  protected createIpAddressItemAction: any;

  @IpAddressModule.Action("findOne")
  protected findOneIpAddressItemAction: any;

  @IpAddressModule.Action("update")
  protected updateIpAddressItemAction: any;

  @IpAddressModule.Action("findAll")
  protected findAllIpAddressItemsAction: any;

  @IpAddressModule.Action("resetSave")
  protected resetSaveIpAddressAction: any;

  @IpAddressModule.Action("resetList")
  protected resetListIpAddressAction: any;

  @IpAddressModule.Action("del")
  protected delIpAddressItemAction: any;

  @IpAddressModule.Action("findAllSubresource")
  protected findAllSubresourceIpAddressItemsAction: any;

  @IpAddressModule.Action("setDataList")
  protected setDataListIpAddressAction: any;

  @IpAddressModule.Action("upload")
  protected uploadIpAddressAction: any;

  /**
   * Getters
   */
  @IpAddressModule.Getter("getError")
  protected getIpAddressError: any;

  @IpAddressModule.Getter("getIsLoading")
  protected getIpAddressIsLoading: any;

  @IpAddressModule.Getter("getIsSaving")
  protected getIpAddressIsSaving: any;

  @IpAddressModule.Getter("getDataItem")
  protected getIpAddressItem: any;

  @IpAddressModule.Getter("getSuccess")
  protected getIpAddressSuccess: any;

  @IpAddressModule.Getter("getDataList")
  protected getIpAddressList: any;

  @IpAddressModule.Getter("getTotal")
  protected getIpAddressTotal: any;

  @IpAddressModule.Getter("getDeletedSuccess")
  protected getIpAddressDeletedSuccess: any;

  @IpAddressModule.Getter("getValidationErrors")
  protected getIpAddressValidationErrors: any;

  /**
   * Mutations
   */
  @IpAddressModule.Mutation("IS_LOADING")
  protected setIpAddressLoading: any;

  @IpAddressModule.Mutation("SET_IS_SAVING")
  protected setIpAddressIsSaving: any;

  @IpAddressModule.Mutation("SET_ERROR")
  protected setIpAddressError: any;

  @IpAddressModule.Mutation("SET_SUCCESS")
  protected setIpAddressSuccess: any;

  @IpAddressModule.Mutation("SET_DATA_ITEM")
  protected setIpAddressItem: any;

  @IpAddressModule.Mutation("SET_DATA_LIST")
  protected setIpAddressList: any;

  @IpAddressModule.Mutation("SET_TOTAL")
  protected setIpAddressTotal: any;

  @IpAddressModule.Mutation("SET_DELETED_SUCCESS")
  protected setIpAddressDeletedSuccess: any;

  @IpAddressModule.Mutation("SET_VALIDATION_ERRORS")
  protected setIpAddressValidationErrors: any;

  // Additional...
}
